<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Leven met Dementie - Medicatie</h1>
        <div class="row">
          <p class="intro-section-text">
            <b>Toelichting:</b> Onderstaand dashboard geeft informatie weer over de <b>Medicatie</b> als onderdeel van
            de levenspadfase <b>Leven met Dementie</b>. Doel van onderstaande overzichten is om de gebruiker van dit
            dashboard inzicht te geven in het medicijngebruik door mensen met dementie in Nederland, in het bijzonder in
            zorgkantoorregio's of in dementienetwerkregio's. Twee termen worden vaak toegepast in geval van
            medicijngebruik: psychofarmaca enerzijds en polyfarmacie anderzijds. Onder psychofarmaca worden
            antipsychotica, antidepressiva, anxiolytica, hypnotica, anti-epileptica en middelen bij dementie verstaan.
            Polyfarmacie wordt gebruikt als een persoon vijf of meer verschillende soorten medicijnen voorgeschreven
            heeft gekregen.
          </p>
          <p class="intro-section-text">
            In deze grafieken wordt uitsluitend data over thuiswonende mensen met dementie getoond. Medicijnen verstrekt
            tijdens ziekenhuisopname en medicatiedata van mensen wonend in een verpleeghuis zijn niet beschikbaar.
          </p>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Medicatie thuiswonenden in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat medicatie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="zorgkantoorregionaam_m" :y="gebruikt_medicatie_table.map(value => `${value}%`)" :xtitle="'Regio namen'"
          :ytitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Medicatie thuiswonenden in {{ selectedYear }}</template>
          <div slot="card-body">
            In aflopende volgorde van ofwel zorgkantoren ofwel dementienetwerken is het percentage te zien van
            thuiswonende mensen
            met dementie dat in {{ selectedYear }} medicatie heeft voorgeschreven gekregen.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met medicatie'" :names="['%Gebruikt medicatie']"
              :x="[zorgkantoorregionaam_m]" :y="[gebruikt_medicatie]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Percentage met medicatie in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor de thuiswonende mensen met dementie in deze grafiek komt uit de database met CBS microdata. De informatie over het percentage medicatiegebruik van de verschillende leeftijdsgroepen in de algemene bevolkingsgroepen komt uit: https://opendata.cbs.nl/#/CBS/nl/dataset/81071ned/table?defaultview'"
          :x="groep" :y="percentage_met_medicatie_table.map(value => `${value}%`)" :xtitle="'Groep'"
          :ytitle="'Percentage'" :tableHeight='300'>
          <template slot="card-header">Percentage met medicatie in {{ selectedYear }}</template>
          <div slot="card-body">
            Voor de algemene bevolking is weergegeven welk percentage medicatie heeft voorgeschreven gekregen in
            {{ selectedYear }}.
            <simple-bar-chart-v v-if="load.graphFour" :marginBottom="175" :ytitle="'% met medicatie'" :tickangle="0"
              :x="groep" :y="percentage_met_medicatie" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Psychofarmaca thuiswonenden in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat psychofarmaca gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="zorgkantoorregionaam_ps" :y="gebruikt_psycho_table.map(value => `${value}%`)" :xtitle="'Regio namen'"
          :ytitle="'Psychofarmaca percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Psychofarmaca thuiswonenden in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in {{ selectedYear }}
            psychofarmaca voorgeschreven heeft gekregen.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met psychofarmaca'"
              :name="['%Gebruikt psychofarmaca']" :x="[zorgkantoorregionaam_ps]" :y="[gebruikt_psycho]"
              :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Psychofarmacaverstrekking in ${selectedYear}`"
          :hovertext="'De indeling naar type ondersteuning zoals gebruikt door het CBS is aangehouden. De informatie over de mensen met dementie komt uit de database met CBS microdata. De informatie over de algemene bevolkingsgroepen komt uit: https://opendata.cbs.nl/#/CBS/nl/dataset/81071ned/table?defaultview'"
          :x="bevolkingsgroep" :y="antidepressiva_table.map(value => `${value}%`)"
          :z="hypnotica_table.map(value => `${value}%`)" :w="anxiolytica_table.map(value => `${value}%`)"
          :v="antipsychotica_table.map(value => `${value}%`)" :t="antidementie_table.map(value => `${value}%`)"
          :xtitle="'Groep'" :ytitle="'Antidepressiva'" :ztitle="'Hypnotica'" :wtitle="'Anxiolytica'"
          :vtitle="'Antipsychotica'" :ttitle="'Antidementie medicatie'" :tableHeight='300'>
          <template slot="card-header">Psychofarmacaverstrekking in {{ selectedYear }}</template>
          <div slot="card-body">
            Percentage personen dat 1 of meer verschillende typen psychofarmaca verstrekt kreeg (percentage van de
            totale
            groep) in {{ selectedYear }}.
            <grouped-bar-chart :ytitle="'% met medicatie'" v-if="load.graphThree" :marginBottom="180"
              :tickformat="',.0%'" :tickangle="0"
              :names="['Antidepressiva', 'Hypnotica', 'Anxiolytica', 'Antipsychotica', 'Antidementie medicatie']"
              :x="[bevolkingsgroep, bevolkingsgroep, bevolkingsgroep, bevolkingsgroep, bevolkingsgroep]"
              :y="[antidepressiva, hypnotica, anxiolytica, antipsychotica, antidementie]" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Polyfarmacie thuiswonenden in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat polyfarmacie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="zorgkantoorregionaam_pl" :y="perc_poly_table.map(value => `${value}%`)" :xtitle="'Regio namen'"
          :ytitle="'Polyfarmacie percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Polyfarmacie thuiswonenden in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in {{ selectedYear }}
            polyfarmacie heeft voorgeschreven gekregen.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met polyfarmacie'"
              :names="['%Gebruikt polyfarmacie']" :x="[zorgkantoorregionaam_pl]" :y="[perc_poly]" :ticksuffix="'%'"
              :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Polyfarmacie per leeftijdsgroep in ${selectedYear}`"
          :hovertext="'In deze grafiek is het aantal thuiswonende mensen met dementie met polyfarmacie (vijf of meer verschillende soorten medicijnen) als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven. Bron: Medicatietab van CBS door Zorginstituut Nederland, met daarin medicijnverstrekkingen aan personen in de Basis Registratie Personen. Medicijnen verstrekt tijdens ziekenhuisopname of door verpleeghuis zijn niet meegenomen. '"
          :x="leeftijd" :y="perc_polyfarmacie_table.map(value => `${value}%`)" :xtitle="'Age groep'"
          :ytitle="'Percentage'" :tableHeight='300'>
          <template slot="card-header">Polyfarmacie per leeftijdsgroep in {{ selectedYear }} </template>
          <div slot="card-body">
            In deze grafiek is in verschillende leeftijdsklassen het percentage te zien van thuiswonende mensen met
            dementie
            dat in {{ selectedYear }} vijf of meer medicijnen heeft voorgeschreven gekregen.
            <simple-bar-chart-v v-if="load.graphFive" :marginBottom="50" :xtitle="'Leeftijdsgroep'"
              :ytitle="'% met polyfarmacie'" :x="leeftijd" :y="perc_polyfarmacie" />
          </div>
        </card>
      </div>
    </div>


    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Meest verstrekte medicatie in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. Hierin wordt de medicatie van de thuiswonende groep mensen met dementie weergegeven.'"
          :x="medicijn" :y="aantal_table.map(value => `${value}%`)" :xtitle="'Top 10 verstrekte medicatieAantal'"
          :ytitle="'Aantal'" :tableHeight='430'>
          <template slot="card-header">Meest verstrekte medicatie in {{ selectedYear }} </template>
          <div slot="card-body">
            Top 10 verstrekte medicatie aan thuiswonende mensen met dementie als percentage van het totaal aantal
            verstrekkingen in {{ selectedYear }}.
            <horizontal-bar-chart v-if="load.graphTwo" :xtitle="'% met medicatie'" :x="aantal" :y="medicijn"
              :marginleft="400" :height="400" :width="1200" />
          </div>
        </card>
      </div>
    </div>

    <!-- Landelijk Post -->
    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Medicatie thuiswonenden'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat medicatie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="jaar_medicatie" :y="gebruikt_medicatie_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight='400'>
          <template slot="card-header">Medicatie thuiswonenden</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijk niveau van het percentage thuiswonende mensen met
            dementie
            dat medicijnen voorgeschreven heeft gekregen.
            <stacked-bar-chart-colors v-if="load.graphOne" :isLandelijk="false" :ytitle="'% met medicatie'"
              :xtitle="'Jaar'" :x="[jaar_medicatie]" :y="[gebruikt_medicatie]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Psychofarmaca thuiswonenden'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat psychofarmaca gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="jaar_medicatie" :y="gebruikt_psycho_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight='350'>
          <template slot="card-header">Psychofarmaca thuiswonenden</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijk niveau van het percentage thuiswonende personen met
            dementie
            dat psychofarmaca heeft voorgeschreven.
            <stacked-bar-chart-colors v-if="load.graphOne" :isLandelijk="false" :ytitle="'% met psychofarmaca'"
              :xtitle="'Jaar'" :x="[jaar_medicatie]" :y="[gebruikt_psycho]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Polyfarmacie thuiswonenden'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat polyfarmacie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'"
          :x="jaar_medicatie" :y="perc_poly_table.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight='350'>
          <template slot="card-header">Polyfarmacie thuiswonenden</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijk niveau van het percentage thuiswonende personen met
            dementie
            dat psychofarmaca heeft voorgeschreven.
            <stacked-bar-chart-colors v-if="load.graphOne" :isLandelijk="false" :ytitle="'% met polyfarmacie'"
              :xtitle="'Jaar'" :x="[jaar_medicatie]" :y="[perc_poly]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>

<script>

import Card from '../components/Card'
import HorizontalBarChart from '../components/HorizontalBarChart'
import GroupedBarChart from '../components/GroupedBarChart'
import StackedBarChartColors from '../components/StackedBarChartColors'
import SimpleBarChartV from '../components/SimpleBarChartV'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Medicatie',
  },
  components: { Card, HorizontalBarChart, GroupedBarChart, StackedBarChartColors, SimpleBarChartV, Footer },
  data() {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_m: [],
      zorgkantoorregionaam_ps: [],
      zorgkantoorregionaam_pl: [],
      gebruikt_medicatie: [],
      gebruikt_medicatie_table: [],
      jaar_medicatie: [],
      leeftijd: [],
      perc_polyfarmacie: [],
      perc_polyfarmacie_table: [],
      gebruikt_psycho: [],
      gebruikt_psycho_table: [],
      perc_poly: [],
      perc_poly_table: [],
      medicijn: [],
      aantal_table: [],
      aantal: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
      },
      bevolkingsgroep: [],
      antidepressiva: [],
      antidementie: [],
      hypnotica: [],
      anxiolytica: [],
      antipsychotica: [],
      antidepressiva_table: [],
      antidementie_table: [],
      hypnotica_table: [],
      anxiolytica_table: [],
      antipsychotica_table: [],
      groep: [],
      percentage_met_medicatie: [],
      percentage_met_medicatie_table: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.load.graphThree = false
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadMedicatieData()
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData(level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage_medicatie - a.percentage_medicatie)
            // response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Landelijk')
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie * 100))
            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie * 100).toFixed(1))
            this.zorgkantoorregionaam_m = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_psychofarmaca - a.percentage_psychofarmaca)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca * 100))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca * 100).toFixed(1))
            this.zorgkantoorregionaam_ps = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_polyfarmacieZonderJ - a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100).toFixed(1))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.zorgkantoorregionaam)
            this.load.graphOne = true
          }
        )
      }
      else if (level === 'landelijk') {
        this.$http.get(`/api/zorgkantoor_medicatie/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            this.jaar_medicatie = response.data.map(d => d.jaar)
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie * 100))
            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie * 100).toFixed(1))
            this.zorgkantoorregionaam_m = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_psychofarmaca - a.percentage_psychofarmaca)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca * 100))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca * 100).toFixed(1))
            this.zorgkantoorregionaam_ps = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_polyfarmacieZonderJ - a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100).toFixed(1))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.zorgkantoorregionaam)
            this.load.graphOne = true
          }
        )
      }
      else {
        this.$http.get(`/api/dementienetwerk_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage_medicatie - a.percentage_medicatie)
            response.data = response.data.filter(x => x.dementienetwerknaam !== 'Landelijk')
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie * 100))
            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie * 100).toFixed(1))
            this.zorgkantoorregionaam_m = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a, b) => b.percentage_psychofarmaca - a.percentage_psychofarmaca)
            this.zorgkantoorregionaam_ps = response.data.map(d => d.dementienetwerknaam)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca * 100))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca * 100).toFixed(1))
            response.data.sort((a, b) => b.percentage_polyfarmacieZonderJ - a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ * 100).toFixed(1))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.dementienetwerknaam)
            this.load.graphOne = true
          }
        )
      }
    },
    loadMedicatieData() {
      this.$http.get(`/api/medicatie_leeftijd/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a, b) => a.aandeel_polyfarmacie - b.aandeel_polyfarmacie)
          this.perc_polyfarmacie = response.data.map(d => (d.aandeel_polyfarmacie))
          this.perc_polyfarmacie_table = response.data.map(d => (d.aandeel_polyfarmacie * 100).toFixed(1))
          this.leeftijd = response.data.map(d => d.leeftijd)
          this.load.graphFive = true
        }
      ),
        this.$http.get(`/api/bevolking_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.bevolkingsgroep = response.data.map(d => d.groep)
            this.antidepressiva = response.data.map(d => (d.percentage_antidepressiva))
            this.antidementie = response.data.map(d => (d.percentage_antidementie))
            this.hypnotica = response.data.map(d => (d.percentage_hypnotica))
            this.anxiolytica = response.data.map(d => (d.percentage_anxiolytica))
            this.antipsychotica = response.data.map(d => (d.percentage_antipsychotica))

            this.antidepressiva_table = response.data.map(d => (d.percentage_antidepressiva * 100).toFixed(1))
            this.antidementie_table = response.data.map(d => (d.percentage_antidementie * 100).toFixed(1))
            this.hypnotica_table = response.data.map(d => (d.percentage_hypnotica * 100).toFixed(1))
            this.anxiolytica_table = response.data.map(d => (d.percentage_anxiolytica * 100).toFixed(1))
            this.antipsychotica_table = response.data.map(d => (d.percentage_antipsychotica * 100).toFixed(1))
            this.load.graphThree = true
          }
        ),
        this.$http.get(`/api/medicatie/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => a.aantal - b.aantal)
            this.medicijn = response.data.map(d => d.ATC).slice(-10)
            this.aantal = response.data.map(d => (d.aantal / d.observaties)).slice(-10)
            this.aantal_table = response.data.map(d => ((d.aantal / d.observaties) * 100).toFixed(1)).slice(-10)
            this.load.graphTwo = true
          }
        ),
        this.$http.get(`/api/bevolking_medicatie/get_data_medicatie/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => a.aantal - b.aantal)
            this.percentage_met_medicatie = response.data.map(d => (d.percentage_met_medicatie))
            this.percentage_met_medicatie_table = response.data.map(d => (d.percentage_met_medicatie * 100).toFixed(1))
            this.groep = response.data.map(d => d.groep)
            this.load.graphFour = true
          }
        )
    }
  },
  mounted() {
    this.loadAllData(this.selectedLevel),
      this.loadMedicatieData()
  }
}

</script>

<style scoped>
.col-sm-4 {
  margin-top: 10px !important;
}

.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}
</style>
